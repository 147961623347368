import React, { useState, useEffect } from 'react';
import { sendOTP, verifyOTP, auth } from '../firebase';  // Import OTP functions and auth from firebase.js
import { getFirestore, doc, getDoc } from "firebase/firestore";  // Firestore for Employee ID validation
import { RecaptchaVerifier } from 'firebase/auth';  // Import RecaptchaVerifier from Firebase Auth
import commonStyles from './commonStyles';
import logo from '../assets/logo.png';  // Import the logo

function OperatorLogin() {
  const [employeeID, setEmployeeID] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+91');  // Default to India (+91)
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);  // Track OTP state
  const [confirmationResult, setConfirmationResult] = useState(null);  // Track Firebase confirmation result
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);  // Track the reCAPTCHA instance

  const db = getFirestore();  // Initialize Firestore

  // Initialize reCAPTCHA when component mounts and ensure auth is ready
  useEffect(() => {
    if (!recaptchaVerifier && auth) {
      try {
        const verifier = new RecaptchaVerifier('recaptcha-container', {
          size: 'invisible',  // Invisible reCAPTCHA for seamless user experience
          callback: (response) => {
            console.log('reCAPTCHA verified:', response);
          },
          'expired-callback': () => {
            console.log('reCAPTCHA expired, please try again.');
          }
        }, auth);
        
        verifier.render();  // Render reCAPTCHA
        setRecaptchaVerifier(verifier);  // Save verifier instance to state
      } catch (error) {
        console.error('Error initializing reCAPTCHA:', error);
      }
    }
  }, [recaptchaVerifier]);

  // Handle sending OTP based on Phone Number and Employee ID
  const handleSendOtp = async (event) => {
    event.preventDefault();

    const fullPhoneNumber = `${countryCode}${phoneNumber}`;  // Combine country code with phone number

    try {
      // Send OTP using Firebase function from firebase.js, passing in the verifier
      const confirmationResult = await sendOTP(fullPhoneNumber, recaptchaVerifier);  // Pass recaptchaVerifier to the sendOTP function
      setConfirmationResult(confirmationResult);  // Store confirmation result for verifying OTP
      setOtpSent(true);  // OTP sent successfully
      alert('OTP has been sent to your registered phone number.');
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Error sending OTP. Please check your Employee ID and phone number.');
    }
  };

  // Handle OTP verification
  const handleVerifyOtp = async (event) => {
    event.preventDefault();

    try {
      // Verify OTP using Firebase function from firebase.js
      await verifyOTP(otp, employeeID);
      alert('OTP verified successfully!');
      window.location.href = `/dpr`;  // Redirect after OTP success
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('OTP verification failed. Please try again.');
    }
  };

  return (
    <div style={commonStyles.container}>
      <img src={logo} alt="Techblaiz Logo" style={commonStyles.logo} />  {/* Use the logo */}
      
      {!otpSent ? (
        <form style={commonStyles.form} onSubmit={handleSendOtp}>
          <input
            type="text"
            placeholder="Employee ID *"
            style={commonStyles.input}
            value={employeeID}
            onChange={(e) => setEmployeeID(e.target.value)}  // Capture Employee ID input
            required
          />
          <div style={commonStyles.row}>
            <select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              style={{ ...commonStyles.input, flex: 0.2, marginRight: '10px' }}  // Dropdown for country code
            >
              <option value="+91">+91 (India)</option>
              <option value="+1">+1 (USA)</option>
              {/* Add more country codes as needed */}
            </select>
            <input
              type="tel"
              placeholder="Phone Number *"
              style={{ ...commonStyles.input, flex: 0.8 }}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}  // Capture phone number input
              required
            />
          </div>
          <button id="send-otp-button" type="submit" style={commonStyles.button}>SEND OTP</button>  {/* reCAPTCHA verifier button */}
          <div id="recaptcha-container"></div>  {/* Invisible reCAPTCHA container */}
        </form>
      ) : (
        <form style={commonStyles.form} onSubmit={handleVerifyOtp}>
          <input
            type="text"
            placeholder="Enter OTP *"
            style={commonStyles.input}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}  // Capture OTP input
            required
          />
          <button type="submit" style={commonStyles.button}>VERIFY OTP</button>
        </form>
      )}
    </div>
  );
}

export default OperatorLogin;
