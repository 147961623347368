import React, { useState } from 'react';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Button, TextField } from '@mui/material';
import { Edit, Delete, Add, Search } from '@mui/icons-material';
import commonStyles from './commonStyles';  // Assuming you have some shared styles
import ReusableDialog from './ReusableDialog';  // Import reusable dialog
import ReusableForm from './ReusableForm';  // Import reusable form
import { getFields } from './formFields';  // Utility function for field definitions
import { handleSave, handleInputChange } from './formHandlers';  // Utility functions for handling form

const PartsInventory = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);  // Dialog state for Add Part
  const [parts, setParts] = useState([
    { id: 'PART001', name: 'Bolt', category: 'Hardware', stock: '50', price: '$0.50' },
    { id: 'PART002', name: 'Nut', category: 'Hardware', stock: '100', price: '$0.30' }
  ]);  // Store parts state here
  const [newPart, setNewPart] = useState({ id: '', name: '', category: '', stock: '', price: '' });

  // Handle opening and closing the Add Part dialog
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle saving the new part
  const handleSavePart = () => {
    handleSave(parts, setParts, newPart, () => setNewPart({ id: '', name: '', category: '', stock: '', price: '' }), handleClose);
  };

  // Filter parts based on search input
  const filteredParts = parts.filter((part) =>
    part.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    part.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
    part.id.toLowerCase().includes(searchQuery.toLowerCase()) ||  // Filter by ID
    part.price.toLowerCase().includes(searchQuery.toLowerCase())  // Filter by price
  );

  // Define fields for the Add Part form using the getFields utility function
  const partFields = getFields('partInventory');

  return (
    <Box sx={commonStyles.pageContainer}>
      {/* Search Field and Add Part Button */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <TextField
          label="Search Parts"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
          }}
          sx={{ marginRight: '20px', width: '300px' }}
        />

        {/* Add Part Button */}
        <Button variant="contained" sx={commonStyles.button} startIcon={<Add />} onClick={handleOpen}>
          Add Part
        </Button>
      </Box>

      {/* Parts Table */}
      <Table sx={{ backgroundColor: '#FFFFFF' }}>
        <TableHead sx={commonStyles.tableHeader}>
          <TableRow>
            {partFields.map((field) => (
              <TableCell key={field.name}>{field.label}</TableCell>
            ))}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredParts.map((row) => (
            <TableRow key={row.id} sx={commonStyles.tableRow}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.category}</TableCell>
              <TableCell>{row.stock}</TableCell>
              <TableCell>{row.price}</TableCell>
              <TableCell>
                <IconButton sx={commonStyles.iconButton}><Edit /></IconButton>
                <IconButton sx={commonStyles.deleteButton}><Delete /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Add Part Dialog */}
      <ReusableDialog open={open} onClose={handleClose} onSave={handleSavePart} title="Add New Part">
        <ReusableForm fields={partFields} handleInputChange={(e) => handleInputChange(e, setNewPart, newPart)} formData={newPart} />
      </ReusableDialog>
    </Box>
  );
};

export default PartsInventory;
