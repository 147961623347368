import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';  // Ensure correct import from Firebase
import { auth } from '../firebase';  // Your Firebase config file

const PrivateRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);  // Loading state to wait for Firebase auth check
  const [authenticatedUser, setAuthenticatedUser] = useState(null);

  useEffect(() => {
    console.log('Checking authentication state...');
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User authenticated:', user);
        setAuthenticatedUser(user);
      } else {
        console.log('User not authenticated');
        setAuthenticatedUser(null);
      }
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;  // Show a loading message or spinner while checking auth state
  }

  if (!authenticatedUser) {
    // If not authenticated, redirect to the sign-in page
    return <Navigate to="/signin" />;
  }

  // If authenticated, render the protected route
  return children;
};

export default PrivateRoute;
