// components/common/SearchBar.js
import React from 'react';
import { TextField, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';

const SearchBar = ({ searchQuery, setSearchQuery, placeholder }) => {
  return (
    <TextField
      label={placeholder}
      variant="outlined"
      size="small"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      InputProps={{
        endAdornment: (
          <IconButton>
            <Search />
          </IconButton>
        ),
      }}
      sx={{ marginRight: '20px', width: '300px' }}
    />
  );
};

export default SearchBar;
