// utils/formHandlers.js
export const handleSave = (entityList, setEntityList, entity, resetEntity, closeDialog) => {
    setEntityList([...entityList, entity]);
    resetEntity();
    closeDialog();
  };
  
  export const handleInputChange = (e, setEntity, entity) => {
    setEntity({ ...entity, [e.target.name]: e.target.value });
  };
  