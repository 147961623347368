// components/common/AddButton.js
import React from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';

const AddButton = ({ onClick, label }) => {
  return (
    <Button variant="contained" startIcon={<Add />} onClick={onClick}>
      {label}
    </Button>
  );
};

export default AddButton;
