import React, { useContext, useState } from 'react';
import { UserContext } from './UserContext';
import { Box, Typography, IconButton, Badge, Avatar, Divider } from '@mui/material';
import { Notifications, Person } from '@mui/icons-material';
import NavigationPanel from './NavigationPanel';
import commonStyles from './commonStyles';

const Layout = ({ children, title }) => {
  const { loggedInUser } = useContext(UserContext);  // Get the logged-in user's name from context
  const [collapsed, setCollapsed] = useState(false); // Add collapsed state for the panel

  const handleCollapseToggle = () => {
    setCollapsed(!collapsed); // Toggle the collapse state
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
  {/* Left Navigation Panel */}
  <Box 
    sx={{ 
      width: collapsed ? '60px' : '240px', // Adjust width based on collapsed state
      backgroundColor: '#46B1E1',
      transition: 'width 0.3s ease-in-out',
      padding: 0,  // Ensure no extra padding
    }}
  >
    <NavigationPanel collapsed={collapsed} onToggleCollapse={handleCollapseToggle} />
  </Box>

  {/* Main content area */}
  <Box sx={{ flexGrow: 1, marginLeft: 0, padding: '0px', overflowY: 'auto' }}>  {/* Remove marginLeft if it exists */}
    {/* Top Panel */}
    <Box
      sx={{ 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
        backgroundColor: '#FFFFFF',
        borderBottom: '1px solid #CCCCCC'
      }}
    >
      {/* Title of the page */}
      <Typography variant="h6" sx={commonStyles.heading}>
        {title}
      </Typography>

      {/* Right Side: Notifications and User Info */}
      <Box display="flex" alignItems="center" sx={{ marginLeft: 'auto' }}>
        {/* Notification Bell Icon */}
        <IconButton sx={{ marginRight: '10px' }}>
          <Badge color="secondary" badgeContent={3}>
            <Notifications />
          </Badge>
        </IconButton>

        {/* Divider between Notification and User Avatar */}
        <Divider orientation="vertical" flexItem sx={{ marginRight: '10px', height: '32px', borderColor: '#CCCCCC' }} />

        {/* User Avatar and Name */}
        <Box display="flex" alignItems="center">
          <Avatar sx={{ width: '32px', height: '32px', marginRight: '10px', backgroundColor: 'transparent' }}>
            <Person fontSize="large" style={{ color: '#CCCCCC' }} />
          </Avatar>
          <Typography variant="body1" sx={{ marginRight: '10px' }}>
            {loggedInUser ? loggedInUser.split('@')[0] : 'Guest'}
          </Typography>
        </Box>

        {/* Divider after User Info */}
        <Divider orientation="vertical" flexItem sx={{ marginRight: '10px', height: '32px', borderColor: '#CCCCCC' }} />
      </Box>
    </Box>

    {/* Page Content */}
    <Box sx={{ padding: 0, overflowY: 'auto', fontFamily: commonStyles.pageContainer.fontFamily }}>
      {children}
    </Box>
  </Box>
</Box>
  );
};

export default Layout;
