import React, { useState } from 'react';
import { Box, Typography, Paper, Grid, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import commonStyles from './commonStyles';
import { locationOptions } from './Locations';

function Dashboard() {
  const [selectedLocation, setSelectedLocation] = useState('all');

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const renderPanels = () => {
    const isAggregated = selectedLocation === 'all';
    const locationName = isAggregated ? "All Sites" : locationOptions.find(option => option.id === selectedLocation)?.name || 'Selected Location';

    return (
      <>
        {/* Total Production Panel */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#F1F1F1' }}>
            <Typography variant="h6" sx={{ ...commonStyles.heading, fontSize: '20px' }} gutterBottom>
              Total Production ({locationName})
            </Typography>
            <Typography>
              {isAggregated ? "Aggregate production data from all sites." : `Production metrics for ${locationName}.`}
            </Typography>
          </Paper>
        </Grid>

        {/* Average Unit-Level Efficiency Panel */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#F1F1F1' }}>
            <Typography variant="h6" sx={{ ...commonStyles.heading, fontSize: '20px' }} gutterBottom>
              Average Unit-Level Efficiency ({locationName})
            </Typography>
            <Typography>
              {isAggregated ? "Average efficiency across all units at all sites." : `Efficiency metrics for units at ${locationName}.`}
            </Typography>
          </Paper>
        </Grid>

        {/* Aggregated Shift Performance Panel */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#F1F1F1' }}>
            <Typography variant="h6" sx={{ ...commonStyles.heading, fontSize: '20px' }} gutterBottom>
              Aggregated Shift Performance ({locationName})
            </Typography>
            <Typography>
              {isAggregated ? "Shift performance data across all locations." : `Shift performance data for ${locationName}.`}
            </Typography>
          </Paper>
        </Grid>

        {/* Overall Cycle Time Analysis Panel */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#F1F1F1' }}>
            <Typography variant="h6" sx={{ ...commonStyles.heading, fontSize: '20px' }} gutterBottom>
              Overall Cycle Time Analysis ({locationName})
            </Typography>
            <Typography>
              {isAggregated ? "Overall cycle time data across all units and sites." : `Cycle time analysis for ${locationName}.`}
            </Typography>
          </Paper>
        </Grid>
      </>
    );
  };

  return (
    <Box sx={commonStyles.pageContainer}>

      {/* Location Selector */}
      <FormControl sx={{ minWidth: 200, marginBottom: 3 }}>
        <InputLabel>Location</InputLabel>
        <Select
          value={selectedLocation}
          onChange={handleLocationChange}
          label="Location"
        >
          <MenuItem value="all">All Locations</MenuItem>
          {locationOptions.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Dashboard Panels */}
      <Grid container spacing={3}>
        {renderPanels()}
      </Grid>
    </Box>
  );
}

export default Dashboard;
