import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';  // Import 'Link' and 'useNavigate'
import { auth } from '../firebase';  // Correct Firebase authentication import
import { signInWithEmailAndPassword } from 'firebase/auth';  // Import the Firebase sign-in function
import { apiRequest } from '../services/api';  // Import the API helper function
import commonStyles from './commonStyles';
import logo from '../assets/logo.png';  // Import the logo

function Signin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();  // Hook for programmatic navigation

  const handleSignin = async (event) => {
    event.preventDefault();

    try {
      // Firebase Authentication logic
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const token = await user.getIdToken();  // Get Firebase ID Token
      console.log("Firebase ID Token:", token); //checking for validation

      // Send the token to the Django backend
      await apiRequest('https://ntf.techblaiz.com/api/signin/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),  // Send token to Django backend
      });

      // Redirect to a different page upon successful sign-in (e.g., dashboard)
      navigate('/dashboard');  // Replace '/dashboard' with your desired route
    } catch (error) {
      console.error("Error signing in: ", error.message);
      alert('Error during sign in. Please try again.');
    }
  };

  return (
    <div style={commonStyles.container}>
      <img src={logo} alt="Techblaiz Logo" style={commonStyles.logo} />  {/* Use the logo */}
      <form onSubmit={handleSignin} style={commonStyles.form}>
        <input
          type="email"
          placeholder="Email *"
          value={email}
          onChange={(e) => setEmail(e.target.value)}  // Update email state
          style={commonStyles.input}
          required
        />
        <input
          type="password"
          placeholder="Password *"
          value={password}
          onChange={(e) => setPassword(e.target.value)}  // Update password state
          style={commonStyles.input}
          required
        />
        <button type="submit" style={commonStyles.button}>SIGN IN</button>
      </form>
      <div style={commonStyles.links}>
        <p style={commonStyles.accountText}>
          Don't have an account? <Link to="/signup" style={commonStyles.link}>Sign up</Link>
        </p>
        <p style={commonStyles.accountText}>
          Are you an operator? <Link to="/operator-login" style={commonStyles.link}> Operator Login </Link>
        </p>
      </div>
    </div>
  );
}

export default Signin;
