// Utility function that determines background and text styles
export const getNavItemStyles = (location, path, collapsed) => {
  const isSelected = location.pathname === path;

  return {
    transition: 'all 0.3s ease-in-out',
    backgroundColor: isSelected ? '#007ACC' : '#46B1E1',  // Updated to use the new background color for the menu
    color: isSelected ? '#FFFFFF' : '#000000',  // Black text for normal items, white for selected
    '&:hover': {
      backgroundColor: collapsed ? 'inherit' : '#005493',  // Darker blue when hovered and not collapsed
      color: collapsed ? 'inherit' : '#FFFFFF',  // Hover white text when not collapsed
    },
    width: collapsed ? '60px' : '100%',  // Adjust width when collapsed
    textAlign: collapsed ? 'center' : 'left',  // Center text when collapsed
    paddingLeft: collapsed ? '0' : '16px',  // Adjust padding when collapsed
  };
};

const commonStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#FFFFFF',
    fontFamily: "'Roboto', sans-serif",  // Default font
    fontSize: '16px',  // Default font size
    color: '#005493',  // Navy Blue text color
  },
  pageContainer: {
    padding: '20px',
    backgroundColor: '#FFFFFF',
    fontFamily: "'Roboto', sans-serif",  // Ensure Roboto is used
    fontSize: '16px',  // Default font size
    color: '#005493',
  },
  logo: {
    width: '250px',
    marginBottom: '20px',
  },
  form: {
    width: '100%',
    maxWidth: '500px',
    padding: '20px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: 'none',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '14px',  // Reduced font size for form fields
    color: '#005493',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  input: {
    display: 'block',
    width: '100%',
    padding: '12px',
    marginBottom: '15px',
    borderRadius: '4px',
    border: '1px solid #005493',
    backgroundColor: '#F1F1F1',
    color: '#005493',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '14px',  // Input field font size
  },
  button: {
    width: 'auto',
    padding: '8px 16px',
    backgroundColor: '#46B1E1',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '4px',
    fontSize: '14px',  // Button font size
    fontWeight: '500',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#005493',
    },
  },
  links: {
    marginTop: '15px',
    textAlign: 'center',
  },
  link: {
    color: '#46B1E1',
    textDecoration: 'none',
    fontFamily: "'Inter', sans-serif",  // You seem to want Inter for links
    fontSize: '14px',  // Link font size
    fontWeight: 'normal',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  accountText: {
    color: '#005493',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '14px',
  },
  heading: {
    fontFamily: "'Montserrat', sans-serif",  // Montserrat for headings
    fontWeight: '700',
    fontSize: '30px',  // Increase font size for headings
    color: '#005493',
  },
  extraPop: {
    color: '#46B1E1',
  },
  tableHeader: {
    backgroundColor: '#FFFFFF',
    borderBottom: '2px solid #005493',
    color: '#005493',
    fontWeight: 'bold',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '14px',  // Font size for table headers
    textAlign: 'left',
  },
  tableRow: {
    borderBottom: '1px solid #005493',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '14px',  // Font size for table rows
    textAlign: 'left',
  },
  iconButton: {
    color: '#005493',
  },
  deleteButton: {
    color: '#C62828',
  },
  searchBox: {
    width: '300px',
    marginRight: '20px',
    borderColor: '#005493',
    backgroundColor: '#F1F1F1',
    fontSize: '14px',  // Font size for search box input
  },
  
  // Added styles for table and containers, aligned with previous suggestions
  tableHeader: {
    fontWeight: 'bold',  // Bold font for table headers
    fontSize: '14px',  // Font size for table headers
    color: '#005493',  // Text color for table headers
    borderBottom: '2px solid #005493',  // Add border to the bottom of the header
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9',  // Add background color to every other row for readability
    },
  },
  iconButton: {
    color: '#1976d2',  // Button color for edit and other icons
  },
  deleteButton: {
    color: 'red',  // Delete button color
  },
};

export default commonStyles;
