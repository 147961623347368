// utils/formFields.js
import { locationOptions } from './Locations';  // Adjust the path based on your project structure

export const getFields = (entityType) => {
  const fieldDefinitions = {
    user: [
      { name: 'employeeId', label: 'Employee ID', type: 'text' },
      { name: 'firstName', label: 'First Name', type: 'text' },
      { name: 'lastName', label: 'Last Name', type: 'text' },
      { name: 'location', label: 'Location', type: 'select', options: locationOptions },
      { name: 'mobileNumber', label: 'Mobile Number', type: 'text' },
      { name: 'title', label: 'Title', type: 'text' }
    ],
    location: [
      { name: 'id', label: 'Location ID', type: 'text' },
      { name: 'name', label: 'Location Name', type: 'text' },
      { name: 'city', label: 'City', type: 'text' },
      { name: 'state', label: 'State', type: 'text' },
      { name: 'country', label: 'Country', type: 'text' }
    ],
    partInventory: [
      { name: 'id', label: 'Part ID', type: 'text' },
      { name: 'name', label: 'Name', type: 'text' },
      { name: 'category', label: 'Category', type: 'text' },
      { name: 'stock', label: 'Stock', type: 'number' },
      { name: 'price', label: 'Price', type: 'number' }
    ],
  };

  return fieldDefinitions[entityType];
};
