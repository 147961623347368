import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import commonStyles from './commonStyles';  // Importing common styles
import ReusableDialog from './ReusableDialog';  // Import the reusable dialog
import ReusableForm from './ReusableForm';  // Import the reusable form
import AddButton from './AddButton';  // Reusable Add Button
import SearchBar from './SearchBar';  // Reusable Search Bar
import { getFields } from './formFields';  // Import the field definitions
import { fetchUsers, addUser, editUser, deleteUser } from './userApi';  // Import the user API functions
import { handleSave, handleInputChange } from './formHandlers';  // Centralized form handlers


const UserManagement = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState([]);  // Store users state here
  const [open, setOpen] = useState(false);  // Dialog state for Add User
  const [newUser, setNewUser] = useState({ employeeId: '', firstName: '', lastName: '', location: '', mobileNumber: '', title: '' });

  // Fetch user data from the backend on component mount
  useEffect(() => {
    fetchUsers()
      .then(data => {
        console.log('Fetched users:', data); // Log the fetched users
        setUsers(data);
      })
      .catch(error => console.error('Error fetching users:', error));
  }, []);

  // Handle opening and closing the Add User dialog
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Save handler for Add or Edit User
  const handleSaveUser = async () => {
    try {
      if (newUser.id) {
        console.log('Editing user:', newUser); // Log the user being edited
        await editUser(newUser);
        alert('User updated successfully!');
      } else {
        console.log('Adding new user:', newUser); // Log the new user data
        await addUser(newUser);
        alert('User created successfully!');
      }
  
      // Refetch users after adding/editing
      fetchUsers()
        .then(data => {
          console.log('Updated user list:', data); // Log the updated user list
          setUsers(data);
        })
        .catch(error => console.error('Error fetching users:', error));
      
      // Reset form and close dialog
      setNewUser({ employeeId: '', firstName: '', lastName: '', location: '', mobileNumber: '', title: '' });
      handleClose();
    } catch (error) {
      console.error(`Error saving user: ${error.message}`);
      alert(`Error: ${error.message}`);
    }
  };
  

  // Pre-populate form with selected user's data and open dialog for editing
  const handleEdit = (user) => {
    console.log('Editing user with data:', user); // Log the user data being edited
    setNewUser(user);
    setOpen(true);
  };

  // Delete user handler
  const handleDelete = async (userId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (confirmDelete) {
      try {
        console.log('Deleting user with ID:', userId); // Log the user ID being deleted
        await deleteUser(userId);
        alert('User deleted successfully!');
        setUsers(users.filter(user => user.id !== userId));
      } catch (error) {
        console.error(`Error deleting user: ${error.message}`);
        alert(`Error: ${error.message}`);
      }
    }
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Filter users based on search input
  const filteredUsers = users.filter((user) => {
    const firstNameMatch = user.firstName ? user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) : false;
    const lastNameMatch = user.lastName ? user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) : false;
    const employeeIdMatch = user.employeeId ? user.employeeId.toLowerCase().includes(searchQuery.toLowerCase()) : false;
  
    return firstNameMatch || lastNameMatch || employeeIdMatch;
  });

  // Get fields for the Add User form using the utility function
  const userFields = getFields('user');

  return (
    <Box sx={commonStyles.pageContainer}>
      {/* Tabs for switching between categories */}
      <Tabs value={activeTab} onChange={handleTabChange} textColor="primary" indicatorColor="primary" sx={{ marginBottom: '20px' }}>
        <Tab label="All Users" />
        <Tab label="Admins" />
        <Tab label="Operators" />
      </Tabs>

      {/* Search Field and Add User Button */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        {/* Reusable Search Bar */}
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder="Search Users" />

        {/* Reusable Add User Button */}
        <AddButton onClick={handleOpen} label="Add User" />
      </Box>

      {/* User Management Table */}
      <Table sx={{ backgroundColor: '#FFFFFF' }}>
        <TableHead sx={commonStyles.tableHeader}>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Employee ID</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>First Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Last Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Location</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Mobile Number</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Title</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsers.map((user) => (
            <TableRow key={user.id} sx={commonStyles.tableRow}>
              <TableCell>{user.employeeId}</TableCell>
              <TableCell>{user.firstName}</TableCell>
              <TableCell>{user.lastName}</TableCell>
              <TableCell>{user.location}</TableCell>
              <TableCell>{user.mobileNumber}</TableCell>
              <TableCell>{user.title}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEdit(user)} sx={commonStyles.iconButton}><Edit /></IconButton>
                <IconButton onClick={() => handleDelete(user.id)} sx={commonStyles.deleteButton}><Delete /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Add or Edit User Dialog */}
      <ReusableDialog open={open} onClose={handleClose} onSave={handleSaveUser} title="Add New User">
        <ReusableForm fields={userFields} handleInputChange={(e) => handleInputChange(e, setNewUser, newUser)} formData={newUser} />
      </ReusableDialog>
    </Box>
  );
};

export default UserManagement;
