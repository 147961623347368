// src/api/userApi.js
const API_URL = process.env.REACT_APP_API_URL;

export const fetchUsers = async () => {
    try {
      const response = await fetch(`${API_URL}/api/fetch_users/`);

      // Check if the response status is 304 (Not Modified)
    if (response.status === 304) {
      console.log('No new data; using cached version or skipping update.');
      return []; // Or you could choose to keep the existing data without an update
    }
      // Check if the response is JSON
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      return data;
    } else {
      // If not JSON, throw an error with the response text
      const errorText = await response.text();
      throw new Error(`Unexpected response format: ${errorText}`);
    }
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};
  
  export const addUser = async (user) => {
    try {
      const response = await fetch(`${API_URL}/api/addUser/`, {  // Corrected string interpolation
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });
  
      if (response.ok) {
        return await response.json();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
    } catch (error) {
      console.error('Error adding user:', error);
      throw error;
    }
  };
  
  export const editUser = async (user) => {
    try {
      const response = await fetch(`${API_URL}/api/updateUser/${user.id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });
  
      if (response.ok) {
        return await response.json();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };
  
  export const deleteUser = async (userId) => {
    try {
      const response = await fetch(`${API_URL}/api/deleteUser/${userId}/`, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        return { success: true };
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
    }
  };
  