// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence, signOut, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";  // Firebase Authentication with persistence and sign-out
import { getAnalytics } from "firebase/analytics";  // Firebase Analytics
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";  // Firestore for storing Employee ID and other data

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBkT0aAxOIYeR8XOFFZyih6retvBF13SRU",
  authDomain: "vizlyze-474a9.firebaseapp.com",
  projectId: "vizlyze-474a9",
  storageBucket: "vizlyze-474a9",
  messagingSenderId: "883687346226",
  appId: "1:883687346226:web:681b1c18fd7c7b446bec2b",
  measurementId: "G-VKDYF035E9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
const analytics = getAnalytics(app);  // Analytics for tracking usage

// Initialize Firebase Authentication
const auth = getAuth(app);

// Disable app verification only for localhost
if (window.location.hostname === "localhost") {
  auth.useDeviceLanguage();  // Use the device language to handle language-specific settings
  if (auth && auth.settings) {
    auth.settings.appVerificationDisabledForTesting = true;  // Disable reCAPTCHA for testing
  } else {
    console.error('Firebase auth or settings is not initialized correctly');
  }
}

// Initialize Firestore for Employee ID storage
const db = getFirestore(app);

// Set persistence for Firebase authentication to persist across browser sessions
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('Auth persistence set to browserLocalPersistence');
  })
  .catch((error) => {
    console.error('Error setting persistence:', error);
  });

// Sign-out function, which can be used later when needed in your app
const handleSignOut = () => {
  signOut(auth)
    .then(() => {
      console.log('User signed out');
      // You can add redirect logic or display a message here
    })
    .catch((error) => {
      console.error('Error signing out:', error);
    });
};

// OTP-based login using Firebase phone authentication
const sendOTP = (phoneNumber, appVerifier) => {
  return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then((confirmationResult) => {
      // Store confirmationResult for verifying OTP later
      window.confirmationResult = confirmationResult;
      console.log("OTP sent to", phoneNumber);
    })
    .catch((error) => {
      console.error("Error sending OTP:", error);
    });
};

// Verify OTP and link Employee ID to Firebase UID
const verifyOTP = (otp) => {
  const confirmationResult = window.confirmationResult;
  if (confirmationResult) {
    confirmationResult.confirm(otp)
      .then((result) => {
        const user = result.user;
        console.log("OTP verified, user signed in:", user);
        // Proceed to next step after OTP verification
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
      });
  } else {
    console.error("Confirmation result not found. Make sure the OTP was sent.");
  }
};

// Store or validate Employee ID in Firestore
const storeOrValidateEmployeeID = (employeeId, userId) => {
  const employeeRef = doc(db, 'employees', userId);
  getDoc(employeeRef)
    .then((docSnapshot) => {
      if (docSnapshot.exists()) {
        // Validate Employee ID with the existing Firestore record
        const employeeData = docSnapshot.data();
        if (employeeData.employeeId === employeeId) {
          console.log("Employee ID validated successfully");
        } else {
          console.error("Employee ID mismatch");
        }
      } else {
        // If not found, store the new Employee ID
        setDoc(employeeRef, { employeeId })
          .then(() => {
            console.log("Employee ID stored successfully");
          })
          .catch((error) => {
            console.error("Error storing Employee ID:", error);
          });
      }
    })
    .catch((error) => {
      console.error("Error accessing employee data:", error);
    });
};

// Export both auth, analytics, handleSignOut, and OTP functions for use in other components
export { auth, analytics, handleSignOut, sendOTP, verifyOTP };  // Added OTP functions
export default app;  // Export the app for broader use in your project
