import React from 'react';
import { TextField, Box, MenuItem } from '@mui/material';

const ReusableForm = ({ fields, handleInputChange, formData }) => {
  return (
    <Box>
      {fields.map((field) => (
        field.type === 'select' ? (
          <TextField
            key={field.name}
            name={field.name}
            label={field.label}
            value={formData[field.name] || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            select  // This enables the TextField to act as a dropdown
          >
            <MenuItem value="">Select a Location</MenuItem>
            {field.options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            key={field.name}
            name={field.name}
            label={field.label}
            value={formData[field.name] || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        )
      ))}
    </Box>
  );
};

export default ReusableForm;
