import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

function InspectionReport() {
  return (
    
        <Paper elevation={3} style={{ padding: '20px' }}>
          
          {/* Add your Inspection Report specific content here */}
        </Paper>
     
  );
}

export default InspectionReport;
