import React, { useState } from "react";
import { Grid, Paper, Typography, Container, Button, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, MenuItem, Select, InputLabel, FormControl, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// Existing data constants
const timeSlots = ["07:00-08:00", "08:00-09:00", "09:00-10:00", "10:00-11:00", "11:00-12:00", "12:00-13:00"];
const operators = ["Vaibhav Jaisingh", "Yash Kapoor", "Aman Rawat", "Shivam Sharma"];
const locations = ["Manesar", "Ahmedabad", "Pune", "Bangalore"];
const rmkOptions = ["None", "Start Up", "Short Moulding", "Silver Mark", "Tool Breakdown"];
const dtRmkOptions = ["None", "Schedule Shutdown", "Equipment Failure", "Tool Breakdown"];

// Styled Table Row to alternate colors
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function DPRForm() {
  const [formValues, setFormValues] = useState({
    shift: "",
    operatorName: "",
    location: "",
    date: "",
    partName: "",
    materialName: "",
    grade: "",
    rmLot: "",
    stdCt: "",
    actCt: "",
    stdWt: "",
    actWt: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <Container maxWidth="lg" sx={{ my: 5 }}>
      {/* Merged Title from the DPR */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#E6E6E6' }}>
            <Typography variant="h4" align="center" sx={{ color: '#005493', fontWeight: 'bold' }}>
              Daily Production Report (DPR)
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Form Section */}
      <Card variant="outlined" sx={{ my: 3, p: 3, backgroundColor: '#F2F2F2' }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ color: '#005493' }}>
            Production Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel>Shift</InputLabel>
                <Select
                  value={formValues.shift}
                  onChange={handleInputChange}
                  name="shift"
                  label="Shift"
                >
                  <MenuItem value="A">A</MenuItem>
                  <MenuItem value="B">B</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel>Operator Name</InputLabel>
                <Select
                  value={formValues.operatorName}
                  onChange={handleInputChange}
                  name="operatorName"
                  label="Operator Name"
                >
                  {operators.map((operator, index) => (
                    <MenuItem key={index} value={operator}>
                      {operator}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel>Location</InputLabel>
                <Select
                  value={formValues.location}
                  onChange={handleInputChange}
                  name="location"
                  label="Location"
                >
                  {locations.map((location, index) => (
                    <MenuItem key={index} value={location}>
                      {location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                type="date"
                label="Date"
                name="date"
                value={formValues.date}
                onChange={handleInputChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          {/* Part Information Section */}
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Grid item xs={12} md={3}>
              <TextField
                label="Part Name"
                name="partName"
                value={formValues.partName}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Material Name"
                name="materialName"
                value={formValues.materialName}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Grade"
                name="grade"
                value={formValues.grade}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="RM Lot No."
                name="rmLot"
                value={formValues.rmLot}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Cycle Time and Weight Section */}
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Grid item xs={12} md={3}>
              <TextField
                label="Std C.T."
                name="stdCt"
                value={formValues.stdCt}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Act C.T."
                name="actCt"
                value={formValues.actCt}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Std W.T."
                name="stdWt"
                value={formValues.stdWt}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Act W.T."
                name="actWt"
                value={formValues.actWt}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Table Section */}
      <Card variant="outlined" sx={{ my: 3, p: 3, backgroundColor: '#F2F2F2' }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ color: '#005493' }}>
            Hourly Production Data
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: '#46B1E1' }}>
                <TableRow>
                  <TableCell>Time</TableCell>
                  <TableCell>Count</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>OK</TableCell>
                  <TableCell>REJ</TableCell>
                  <TableCell>RMK</TableCell>
                  <TableCell>DT</TableCell>
                  <TableCell>DT RMK</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timeSlots.map((time, index) => (
                  <StyledTableRow key={index}>
                    <TableCell>{time}</TableCell>
                    <TableCell>
                      <TextField type="number" variant="outlined" fullWidth />
                    </TableCell>
                    <TableCell>
                      <TextField type="number" variant="outlined" fullWidth />
                    </TableCell>
                    <TableCell>
                      <TextField type="number" variant="outlined" fullWidth />
                    </TableCell>
                    <TableCell>
                      <TextField type="number" variant="outlined" fullWidth />
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth variant="outlined">
                        <Select>
                          {rmkOptions.map((rmk, rmkIndex) => (
                            <MenuItem key={rmkIndex} value={rmk}>
                              {rmk}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField type="number" variant="outlined" fullWidth />
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth variant="outlined">
                        <Select>
                          {dtRmkOptions.map((dtRmk, dtRmkIndex) => (
                            <MenuItem key={dtRmkIndex} value={dtRmk}>
                              {dtRmk}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      {/* Submit Button */}
      <Box display="flex" justifyContent="center">
        <Button variant="contained" color="primary" size="large" sx={{ backgroundColor: '#005493' }}>
          Submit Report
        </Button>
      </Box>
    </Container>
  );
}

export default DPRForm;
