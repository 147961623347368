import React, { useState } from 'react';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Button, TextField } from '@mui/material';
import { Edit, Delete, Add, Search } from '@mui/icons-material';
import commonStyles from './commonStyles';  // Assuming you have some shared styles
import ReusableDialog from './ReusableDialog';  // Import reusable dialog
import ReusableForm from './ReusableForm';  // Import reusable form

// Array for locations to be used as dropdown options
export const locationOptions = [
  { id: 'LOC001', name: 'Unit I – Manesar, Haryana' },
  { id: 'LOC002', name: 'Unit II – Neemrana, Rajasthan' },
  { id: 'LOC004', name: 'Unit IV – Sanand, Gujarat' },
  { id: 'LOC005', name: 'Unit V – Manesar, Haryana' },
  { id: 'LOC006', name: 'Unit VI – Pune, Maharashtra' },
  { id: 'LOC007', name: 'Unit VII – Bangalore, Karnataka' },
  { id: 'LOC008', name: 'Unit VIII – Naviyani, Gujarat' }
];
const Locations = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);  // Dialog state for Add Location
  const [locations, setLocations] = useState([
    { id: 'LOC001', name: 'Unit I', city: 'Manesar', state: 'Haryana', country: 'India' },
    { id: 'LOC002', name: 'Unit II', city: 'Neemrana', state: 'Rajasthan', country: 'India' },
    { id: 'LOC004', name: 'Unit IV', city: 'Sanand', state: 'Gujarat', country: 'India' },
    { id: 'LOC005', name: 'Unit V', city: 'Manesar', state: 'Haryana', country: 'India' },
    { id: 'LOC006', name: 'Unit VI', city: 'Pune', state: 'Maharashtra', country: 'India' },
    { id: 'LOC007', name: 'Unit VII', city: 'Bangalore', state: 'Karnataka', country: 'India' },
    { id: 'LOC008', name: 'Unit VIII', city: 'Naviyani', state: 'Gujarat', country: 'India' }
  ]);  // Store locations state here
  const [newLocation, setNewLocation] = useState({ id: '', name: '', city: '', state: '', country: 'India' });

  // Handle opening and closing the Add Location dialog
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle form input changes
  const handleInputChange = (e) => {
    setNewLocation({ ...newLocation, [e.target.name]: e.target.value });
  };

  // Handle saving the new location, with duplicate check
  const handleSaveLocation = () => {
    const locationExists = locations.some(location => location.id === newLocation.id);
    
    if (locationExists) {
      alert('A location with this ID already exists.');
      return;
    }
    
    setLocations([...locations, { ...newLocation }]);  // Add new location to the list
    setNewLocation({ id: '', name: '', city: '', state: '', country: 'India' });  // Reset form
    handleClose();  // Close the dialog
  };

  // Filter locations based on search input
  const filteredLocations = locations.filter((location) =>
    location.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    location.city.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Define fields for the Add Location form
  const locationFields = [
    { name: 'id', label: 'Location ID', type: 'text' },
    { name: 'name', label: 'Location Name', type: 'text' },
    { name: 'city', label: 'City', type: 'text' },
    { name: 'state', label: 'State', type: 'text' },
    { name: 'country', label: 'Country', type: 'text', defaultValue: 'India', readOnly: true }
  ];

  return (
    <Box sx={commonStyles.pageContainer}>
      {/* Search Field and Add Location Button */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <TextField
          label="Search Locations"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
          }}
          sx={{ marginRight: '20px', width: '300px' }}
        />

        {/* Add Location Button */}
        <Button variant="contained" sx={commonStyles.button} startIcon={<Add />} onClick={handleOpen}>
          Add Location
        </Button>
      </Box>

      {/* Locations Table */}
      <Table sx={{ backgroundColor: '#FFFFFF' }}>
        <TableHead sx={commonStyles.tableHeader}>
          <TableRow>
            <TableCell>Location ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>City</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredLocations.map((row) => (
            <TableRow key={row.id} sx={commonStyles.tableRow}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.city}</TableCell>
              <TableCell>{row.state}</TableCell>
              <TableCell>{row.country}</TableCell>
              <TableCell>
                <IconButton sx={commonStyles.iconButton}><Edit /></IconButton>
                <IconButton sx={commonStyles.deleteButton}><Delete /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Add Location Dialog */}
      <ReusableDialog open={open} onClose={handleClose} onSave={handleSaveLocation} title="Add New Location">
        <ReusableForm fields={locationFields} handleInputChange={handleInputChange} formData={newLocation} />
      </ReusableDialog>
    </Box>
  );
};

export default Locations;
