import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './components/UserContext'; // Import UserProvider
import Signin from './components/Signin';
import Signup from './components/Signup';  // Import Signup Component
import OperatorLogin from './components/OperatorLogin';  // Import OperatorLogin Component
import Dashboard from './components/Dashboard';
import DPR from './components/DPR';
import InspectionReport from './components/InspectionReport';
import UserManagement from './components/UserManagement';
import PartsInventory from './components/PartsInventory';
import Locations from './components/Locations';
import Layout from './components/Layout';  // Layout is used for pages that require navigation
import PrivateRoute from './components/PrivateRoute';  // Assuming you have this for protecting routes

function App() {
  return (
    <UserProvider> {/* Wrap the app in UserProvider */}
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />  {/* Add route for Signup */}
          <Route path="/operator-login" element={<OperatorLogin />} />  {/* Add route for Operator Login */}

          {/* Protected Routes, wrapped in Layout */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Layout title="Dashboard">
                  <Dashboard />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/dpr"
            element={
              <PrivateRoute>
                <Layout title="Daily Production Report">
                  <DPR />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/inspection-report"
            element={
              <PrivateRoute>
                <Layout title="Inspection Report">
                  <InspectionReport />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/user-management"
            element={
              <PrivateRoute>
                <Layout title="User Management">
                  <UserManagement />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/parts-inventory"
            element={
              <PrivateRoute>
                <Layout title="Parts Inventory">
                  <PartsInventory />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/locations"
            element={
              <PrivateRoute>
                <Layout title="Locations">
                  <Locations />
                </Layout>
              </PrivateRoute>
            }
          />

          {/* Default Route */}
          <Route path="/" element={<Signin />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
