import { auth } from '../firebase';  // Import Firebase to get the current user's ID token

// Function to make an authenticated API request
export const apiRequest = async (endpoint, options = {}) => {
  const token = await auth.currentUser.getIdToken();  // Get Firebase ID Token

  const response = await fetch(endpoint, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,  // Include the Firebase ID token in the Authorization header
      ...options.headers,  // Merge any additional headers
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'API request failed');
  }

  return await response.json();
};
