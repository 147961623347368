import React, { useState, memo } from 'react';
import { List, ListItem, ListItemText, Box, Collapse, IconButton } from '@mui/material';
import { ExpandLess, ExpandMore, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ntflogo from '../assets/ntflogo.svg';
import { getNavItemStyles } from './commonStyles';  // Import the utility function

const NavigationPanel = memo(({ collapsed, onToggleCollapse }) => {
  const location = useLocation();
  const [openSettings, setOpenSettings] = useState(true);
  const navigate = useNavigate();

  const toggleSettings = () => {
    setOpenSettings(!openSettings);
  };

  const handleSignOut = () => {
    navigate('/signin');  // Redirect to the sign-in page
  };

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: '#46B1E1', //for the menu panel
        display: 'flex',
        flexDirection: 'column',
        transition: 'width 0.3s ease-in-out',
        width: collapsed ? '10px' : '240px',  // Adjust width based on collapsed state
      }}
    >
      {/* Logo Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: collapsed ? '8px' : '16px',
          backgroundColor: '#46B1E1', // for the logo panel color
          marginBottom: '20px',
        }}
      >
        <img
          src={ntflogo}
          alt="Company Logo"
          style={{
            width: collapsed ? '40px' : '200px',  // Adjust logo size based on collapse
            height: 'auto',
            transition: 'width 0.3s ease-in-out',
          }}
        />
      </Box>

      {/* Main Navigation Menu */}
      <List sx={{ flexGrow: 1, backgroundColor: '#46B1E1', color: '#000000' }}>

        <ListItem
          button
          component={Link}
          to="/dashboard"
          sx={getNavItemStyles(location, '/dashboard', collapsed)}  // Use the utility function
        >
          <ListItemText primary="Dashboard" sx={{ display: collapsed ? 'none' : 'block' }} />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/dpr"
          sx={getNavItemStyles(location, '/dpr', collapsed)}  // Use the utility function
        >
          <ListItemText primary="Daily Production Report (DPR)" sx={{ display: collapsed ? 'none' : 'block' }} />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/inspection-report"
          sx={getNavItemStyles(location, '/inspection-report', collapsed)}  // Use the utility function
        >
          <ListItemText primary="Inspection Report" sx={{ display: collapsed ? 'none' : 'block' }} />
        </ListItem>

        {/* Settings Option with Collapse */}
        <ListItem button onClick={toggleSettings} sx={getNavItemStyles(location, '', collapsed)}>
          <ListItemText primary="Settings" sx={{ display: collapsed ? 'none' : 'block' }} />
          {openSettings && !collapsed ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={openSettings && !collapsed} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              component={Link}
              to="/user-management"
              sx={getNavItemStyles(location, '/user-management', collapsed)}  // Use the utility function
            >
              <ListItemText primary="User Management" sx={{ display: collapsed ? 'none' : 'block' }} />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/parts-inventory"
              sx={getNavItemStyles(location, '/parts-inventory', collapsed)}  // Use the utility function
            >
              <ListItemText primary="Parts Inventory" sx={{ display: collapsed ? 'none' : 'block' }} />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/locations"
              sx={getNavItemStyles(location, '/locations', collapsed)}  // Use the utility function
            >
              <ListItemText primary="Locations" sx={{ display: collapsed ? 'none' : 'block' }} />
            </ListItem>
          </List>
        </Collapse>

        {/* Sign Out Option */}
        <ListItem
          button
          onClick={handleSignOut}
          sx={getNavItemStyles(location, '/signin', collapsed)}  // Use the utility function for Sign Out as well
        >
          <ListItemText primary="Sign Out" sx={{ display: collapsed ? 'none' : 'block' }} />
        </ListItem>
      </List>

      {/* Collapse Toggle Button at the bottom */}
      <IconButton
        onClick={onToggleCollapse}
        sx={{
          color: '#FFFFFF',
          position: 'absolute',
          bottom: '20px',  // Stick to the bottom
          left: collapsed ? '10px' : '200px',  // Adjust based on collapsed state
          transition: 'left 0.3s ease-in-out',
        }}
      >
        {collapsed ? <ChevronRight sx={{ fontSize: '2.5rem' }} /> : <ChevronLeft sx={{ fontSize: '2.5rem' }} />}
      </IconButton>
    </Box>
  );
});

export default NavigationPanel;
