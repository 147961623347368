import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';  // Correct modular Firebase imports
import { Link } from 'react-router-dom';  // Import Link for navigation
import commonStyles from './commonStyles';  // Assuming you have common styles already set
import logo from '../assets/logo.png';  // Import your logo

// Email validation function
function isValidEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

// Password validation function (minimum 6 characters)
function isValidPassword(password) {
  return password.length >= 6;
}

function Signup() {
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});  // Error state for field validation

  const validateFields = () => {
    const newErrors = {};

    if (!isValidEmail(email)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    if (!isValidPassword(password)) {
      newErrors.password = 'Password must be at least 6 characters long.';
    }

    if (!firstName.trim()) {
      newErrors.firstName = 'First name is required.';
    }

    if (!lastName.trim()) {
      newErrors.lastName = 'Last name is required.';
    }

    if (!companyName.trim()) {
      newErrors.companyName = 'Company name is required.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateFields()) {
      return;  // Stop if there are validation errors
    }

    try {
      const auth = getAuth();  // Initialize Firebase Auth
      // Firebase Authentication logic for signing up
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const token = await user.getIdToken();  // Get Firebase ID Token

      console.log("Firebase Token:", token);

      const response = await fetch('https://ntf.techblaiz.com/api/signup/', {
        method: 'POST',
        body: JSON.stringify({
          token,
          firstName,
          middleName,
          lastName,
          companyName,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error from backend: ${response.statusText}. Details: ${errorText}`);
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        console.log("Backend response:", data);

        await sendEmailVerification(user);

        alert('Signup successful! Please check your email for a verification link. You can now use the platform.');
      } else {
        console.error('Received non-JSON response from backend');
        alert('Unexpected response from the backend');
      }
    } catch (error) {
      console.error("Error during signup: ", error.message);
      alert(`Error during signup: ${error.message}`);
    }
  };

  return (
    <div style={commonStyles.container}>
      <img src={logo} alt="Techblaiz Logo" style={commonStyles.logo} />
      <form onSubmit={handleSubmit} style={commonStyles.form}>
        <div style={commonStyles.row}>
          <input 
            type="text" 
            placeholder="First Name *" 
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)} 
            style={{ ...commonStyles.input, flex: 1, marginRight: '10px' }} 
            required 
          />
          {errors.firstName && <span style={commonStyles.error}>{errors.firstName}</span>}
          <input 
            type="text" 
            placeholder="Middle Name" 
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)} 
            style={{ ...commonStyles.input, flex: 1, marginRight: '10px' }}
          />
          <input 
            type="text" 
            placeholder="Last Name *" 
            value={lastName}
            onChange={(e) => setLastName(e.target.value)} 
            style={{ ...commonStyles.input, flex: 1 }} 
            required 
          />
          {errors.lastName && <span style={commonStyles.error}>{errors.lastName}</span>}
        </div>
        <input 
          type="email" 
          placeholder="Email Address *" 
          value={email}
          onChange={(e) => setEmail(e.target.value)} 
          style={commonStyles.input} 
          required 
        />
        {errors.email && <span style={commonStyles.error}>{errors.email}</span>}
        <input 
          type="password" 
          placeholder="Password *"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={commonStyles.input}
          required
        />
        {errors.password && <span style={commonStyles.error}>{errors.password}</span>}
        <input 
          type="text" 
          placeholder="Company Name *" 
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)} 
          style={commonStyles.input} 
          required 
        />
        {errors.companyName && <span style={commonStyles.error}>{errors.companyName}</span>}
      
        <button type="submit" style={commonStyles.button}>SIGNUP</button>
      </form>

      {/* Links for "Already have an account?" and "Operator Login" */}
      <div style={commonStyles.links}>
        <p style={commonStyles.accountText}>
          Already have an account? <Link to="/signin" style={commonStyles.link}>Sign in</Link>
        </p>
        <p style={commonStyles.accountText}>
          Are you an operator? <Link to="/operator" style={commonStyles.link}>Operator Login </Link>
        </p>
      </div>
    </div>
  );
}

export default Signup;
