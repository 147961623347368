// UserContext.js
import React, { createContext, useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';  // Import Firebase Auth

// Create the UserContext
export const UserContext = createContext();

// Create the UserProvider component
export const UserProvider = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState(null);  // Initially, no user is logged in

  useEffect(() => {
    const auth = getAuth();
    
    // Subscribe to the Firebase auth state change
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, get the user's name or email
        setLoggedInUser(user.displayName || user.email);  // Fallback to email if no display name
      } else {
        // User is signed out
        setLoggedInUser(null);
      }
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ loggedInUser }}>
      {children}
    </UserContext.Provider>
  );
};
